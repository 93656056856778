import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Abans de tot</strong>, recorda que en l’anterior convocatòria de la
                    Guàrdia Urbana de Barcelona, el <strong>79%</strong> dels aspirants aptes van
                    ser alumnes nostres.
                </p>

                <p>
                    Arriba un <strong>nou curs intensiu MIXT de 80 hores presencials</strong>, que
                    inclou temari, psicotècnics i accés a un campus online amb actualitat, cultura
                    general i simulacres.
                </p>

                <p>
                    <strong>Accés immediat al campus online</strong> des del moment de la
                    matriculació.
                </p>

                <p>
                    A més, tots els alumnes rebran el primer dia de curs la{" "}
                    <strong>nova guia de la Guàrdia Urbana de Barcelona 2025</strong> en format
                    llibre i un dossier de psicotècnics basat en els últims exercicis oficials.
                </p>

                <h2>Metodologia iOpos</h2>
                <ul>
                    <li>
                        <span role="img" aria-label="check">
                            ✅
                        </span>{" "}
                        Tots els alumnes inicien i finalitzen junts el curs, fet que permet assolir
                        tots els coneixements de manera progressiva.
                    </li>
                    <li>
                        <span role="img" aria-label="check">
                            ✅
                        </span>{" "}
                        La nostra metodologia es basa en anys d'experiència i estudi.
                    </li>
                    <li>
                        <span role="img" aria-label="check">
                            ✅
                        </span>{" "}
                        El nostre mètode és <strong>estudia i posa't a prova!</strong>
                    </li>
                    <li>
                        <span role="img" aria-label="check">
                            ✅
                        </span>{" "}
                        Tenim el percentatge d'aptes més alt dels últims cinc anys.
                    </li>
                </ul>

                <h2>Contingut del curs presencial</h2>
                <ul>
                    <li>
                        <span role="img" aria-label="book">
                            📖
                        </span>{" "}
                        <strong>80 hores</strong> dedicades al temari i als psicotècnics.
                    </li>
                    <li>
                        <span role="img" aria-label="bulb">
                            💡
                        </span>{" "}
                        Format intensiu centrat en els conceptes més importants per a l’examen, amb
                        anàlisi de temes i preguntes susceptibles de sortir a la prova.
                    </li>
                    <li>
                        <span role="img" aria-label="brain">
                            🧠
                        </span>{" "}
                        Anàlisi de psicotècnics, explicacions i exercicis pràctics.
                    </li>
                    <li>
                        <span role="img" aria-label="clipboard">
                            📋
                        </span>{" "}
                        Simulacres de nivell per consolidar tots els conceptes.
                    </li>
                </ul>

                <h2>Calendari de classes presencials - Grup 1</h2>
                <ul>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 3 de maig (inici)
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 10 de maig
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 17 de maig
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 24 de maig
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 31 de maig
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 7 de juny
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 14 de juny
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 21 de juny
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 28 de juny
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 5 de juliol
                    </li>
                    <li>
                        <span role="img" aria-label="calendar">
                            📅
                        </span>{" "}
                        Dissabte 12 de juliol (fi de curs)
                    </li>
                </ul>

                <p>
                    <strong>A més</strong>, es realitzarà un{" "}
                    <strong>simulacre final a través de ZOOM (3 hores)</strong>.
                </p>

                <h2>Professorat</h2>
                <p>
                    El curs és impartit per una <strong>docent de referència</strong> en la part de
                    temari i per un <strong>especialista en la part de psicotècnics</strong>.
                </p>

                <h2>Ubicació</h2>
                <p>
                    Les classes es realitzaran a{" "}
                    <strong>c/ Martí Codolar, núm. 18, Hospitalet de Llobregat</strong>.
                </p>
                <p>
                    El campus online romandrà obert fins al <strong>30 de juliol</strong>.
                </p>

                <h2>Preu i forma de pagament</h2>
                <p>
                    <strong>Preu del curs: 499,99€</strong>, amb possibilitat de{" "}
                    <strong>pagament en tres terminis sense interessos</strong> i sense matrícula.
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/xB0TcFXlPwg"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't amb el mètode que ha ajudat al 79% dels aspirants aptes a superar les proves de la Guàrdia Urbana de Barcelona!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
